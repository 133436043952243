import {provideHttpClient, withInterceptors} from '@angular/common/http';
import {
    APP_INITIALIZER,
    ENVIRONMENT_INITIALIZER,
    EnvironmentProviders,
    importProvidersFrom,
    inject,
    Provider
} from '@angular/core';
import {MATERIAL_SANITY_CHECKS} from '@angular/material/core';
import {MatDialogModule} from '@angular/material/dialog';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import {PLATFORM_MOCK_API_DEFAULT_DELAY, mockApiInterceptor} from '@platform/lib/mock-api';
import {PlatformConfig} from '@platform/services/config';
import {PLATFORM_CONFIG} from '@platform/services/config/config.constants';
import {PlatformConfirmationService} from '@platform/services/confirmation';
import {platformLoadingInterceptor, PlatformLoadingService} from '@platform/services/loading';
import {PlatformMediaWatcherService} from '@platform/services/media-watcher';
import {PlatformPlatformService} from '@platform/services/platform';
import {PlatformSplashScreenService} from '@platform/services/splash-screen';
import {PlatformUtilsService} from '@platform/services/utils';

export type PlatformProviderConfig = {
    mockApi?: {
        delay?: number;
        services?: any[];
    },
    platform?: PlatformConfig
}

/**
 * Platform provider
 */
export const providePlatform = (config: PlatformProviderConfig): Array<Provider | EnvironmentProviders> => {
    // Base providers
    const providers: Array<Provider | EnvironmentProviders> = [
        {
            // Disable 'theme' sanity check
            provide: MATERIAL_SANITY_CHECKS,
            useValue: {
                doctype: true,
                theme: false,
                version: true,
            },
        },
        {
            // Use the 'fill' appearance on Angular Material form fields by default
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: {
                appearance: 'fill',
            },
        },
        {
            provide: PLATFORM_MOCK_API_DEFAULT_DELAY,
            useValue: config?.mockApi?.delay ?? 0,
        },
        {
            provide: PLATFORM_CONFIG,
            useValue: config?.platform ?? {},
        },

        importProvidersFrom(MatDialogModule),
        {
            provide: ENVIRONMENT_INITIALIZER,
            useValue: () => inject(PlatformConfirmationService),
            multi: true,
        },

        provideHttpClient(withInterceptors([platformLoadingInterceptor])),
        {
            provide: ENVIRONMENT_INITIALIZER,
            useValue: () => inject(PlatformLoadingService),
            multi: true,
        },

        {
            provide: ENVIRONMENT_INITIALIZER,
            useValue: () => inject(PlatformMediaWatcherService),
            multi: true,
        },
        {
            provide: ENVIRONMENT_INITIALIZER,
            useValue: () => inject(PlatformPlatformService),
            multi: true,
        },
        {
            provide: ENVIRONMENT_INITIALIZER,
            useValue: () => inject(PlatformSplashScreenService),
            multi: true,
        },
        {
            provide: ENVIRONMENT_INITIALIZER,
            useValue: () => inject(PlatformUtilsService),
            multi: true,
        },
    ];

    // Mock Api services
    if (config?.mockApi?.services) {
        providers.push(
            provideHttpClient(withInterceptors([mockApiInterceptor])),
            {
                provide: APP_INITIALIZER,
                deps: [...config.mockApi.services],
                useFactory: () => (): any => null,
                multi: true,
            },
        );
    }

    // Return the providers
    return providers;
};
