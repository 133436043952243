export class MetricsHistoryContainer {
    metrics: MetricsHistory[];
    constructor(metrics: MetricsHistory[]) {
        this.metrics = metrics;
    }
}

export class MetricsHistory {
    recycledCount: number;
    availableCount: number;
    damagedCount: number;
    ineligibleCount: number;
    recycleRate: number;
    timestampUTC: string;

    constructor(recycledCount: number, availableCount: number, damagedCount: number, ineligibleCount: number, recycleRate: number, timestampUTC: string) {
        this.recycledCount = recycledCount;
        this.availableCount = availableCount;
        this.damagedCount = damagedCount;
        this.ineligibleCount = ineligibleCount;
        this.recycleRate = recycleRate;
        this.timestampUTC = timestampUTC;
    }
}
