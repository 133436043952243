import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewChild
} from "@angular/core";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {MatMenuModule} from "@angular/material/menu";
import {MatTableDataSource, MatTableModule} from "@angular/material/table";
import {MatSort, MatSortModule} from "@angular/material/sort";
import {CurrencyPipe, DatePipe, DecimalPipe, NgClass, NgIf} from "@angular/common";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatDividerModule} from "@angular/material/divider";
import {map, Observable, of, Subject, takeUntil} from "rxjs";
import {RecyclerLocationService} from "../../../../../core/services/recycler-location.service";
import {RecyclerLocation} from "../../../../../core/models/recycler-location.model";
import { OversightService } from "app/core/services/oversight.service";
import {DashboardTable} from "../../interfaces/dashboard-table.interface";
import {RecycleRatePipe} from "../../pipes/recycle-rate.pipe";

@Component({
    selector: 'location-breakdown-table',
    standalone: true,
    templateUrl: './location-breakdown-table.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MatButtonModule, MatIconModule, MatMenuModule, MatDividerModule, MatTableModule, MatSortModule, NgClass,
        MatProgressBarModule, CurrencyPipe, DatePipe, NgIf, DecimalPipe, RecycleRatePipe
    ]
})
export class LocationBreakdownTableComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('recentTransactionsTable', {read: MatSort}) batchedCommandsTableMatSort: MatSort;
    public batchedCommandsDataSource: MatTableDataSource<DashboardTable> = new MatTableDataSource();
    public recentTransactionsTableColumns: string[] = ['name', 'recycledCount', 'damagedCount', 'ineligibleCount'];
    public recentFoodserviceTransactionsTableColumns: string[] = ['name', 'recycledCount', 'damagedCount', 'ineligibleCount'];
    public recyclerLocations: RecyclerLocation[];
    public isFoodServiceModel: boolean;
    public hasPerformedSearch: boolean = false;
    public loadingCompleted: boolean = false;

    private _unsubscribeAll: Subject<any> = new Subject<any>();


    constructor(private _changeDetectorRef: ChangeDetectorRef,
                private _recyclerLocationService: RecyclerLocationService,
                private _oversightService: OversightService) {
    }

    // -----------------------------------------------------------------------------------------------------
    /** On init */
    // -----------------------------------------------------------------------------------------------------
    ngOnInit(): void {
        this._recyclerLocationService.recyclerLocations$.pipe(takeUntil(this._unsubscribeAll)).subscribe((recyclerLocations: RecyclerLocation[]) => {
            this.recyclerLocations = recyclerLocations;
            this.recyclerLocations.sort((a, b) => b.metrics.recycleRate - a.metrics.recycleRate);
            this.batchedCommandsDataSource.data = this.normalizeRecyclerLocations(this.recyclerLocations);

            // Make sure to reassign the sort after data is updated
            this.batchedCommandsDataSource.sort = this.batchedCommandsTableMatSort;
            this.loadingCompleted = true;
            this._changeDetectorRef.markForCheck();
        });

        this._oversightService.isFoodServiceModel$.pipe(takeUntil(this._unsubscribeAll)).subscribe((isFoodServiceModel: boolean) => {
            this.isFoodServiceModel = isFoodServiceModel;
            if(!isFoodServiceModel)
            {
                if (!this.recentTransactionsTableColumns.includes('availableCount')) {
                    this.recentTransactionsTableColumns.push('availableCount');
                }
                if (!this.recentTransactionsTableColumns.includes('recycleRate')) {
                    this.recentTransactionsTableColumns.push('recycleRate');
                }
            }
            this._changeDetectorRef.markForCheck();
        });
    }

    normalizeRecyclerLocations(locations: RecyclerLocation[]): DashboardTable[] {
        return locations.map(location => {
            return {
                name: location.name,
                recycledCount: location.metrics.recycledCount,
                availableCount: location.metrics.availableCount,
                damagedCount: location.metrics.damagedCount,
                ineligibleCount: location.metrics.ineligibleCount,
                recycleRate: Math.ceil(location.metrics.recycleRate),
            };
        });
    }

    // -----------------------------------------------------------------------------------------------------
    /** After view init */
    // -----------------------------------------------------------------------------------------------------
    ngAfterViewInit(): void {
        this.batchedCommandsDataSource.sort = this.batchedCommandsTableMatSort;
    }

    // -----------------------------------------------------------------------------------------------------
    /** On destroy */
    // -----------------------------------------------------------------------------------------------------
    ngOnDestroy(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    /** Track by function */
    // -----------------------------------------------------------------------------------------------------
    trackByFn(index: number, item: DashboardTable): any {
        return item.name || index;  // Use a unique property or fallback to index
    }
}
