import {Injector} from "@angular/core";
import {bootstrapApplication, createApplication} from '@angular/platform-browser';
import {createCustomElement} from "@angular/elements";
import {CustomerRecyclingDashboardComponent} from "./app/modules/dashboard/dashboard.component";
import {AppComponent} from 'app/app.component';
import {appConfig} from 'app/app.config';

bootstrapApplication(AppComponent, appConfig).catch(err => console.error(err));

(async () => {
    const app = await createApplication(appConfig);
    const injector: Injector = app.injector;
    const element = createCustomElement(CustomerRecyclingDashboardComponent, {injector});
    customElements.define('recycle-mfe', element);
})();

