@if (chartOptions) {
    <div class="flex flex-col flex-auto w-full bg-gray-50 p-4 gap-8 pb-8">

        <!-- loading indicator -->
        <div class="mt-1">
            <div *ngIf="isLoading" class="loader"></div>
        </div>

        <!-- Title and action buttons -->
        <div class="flex-wrap w-full mx-auto">
            <!-- Title and action buttons -->
            <div class="flex w-full"
                 [ngClass]="{'flex-col': isScreenSmall, 'items-center justify-between':!isScreenSmall}">

                <!-- Title -->
                <div class="dashboard-title">
                    <div class="text-3xl font-semibold tracking-tight leading-8">{{ title ?? 'Return Dashboard' }}</div>
                    <div class="font-medium tracking-tight text-secondary">Monitor Recycling Tracker Metrics & Evaluate
                        Performance
                    </div>
                    <div class="text-xs text-secondary">v{{ appVersion }}</div>
                </div>

                <!-- Select Report Period -->
                <div class="flex w-full sm:pt-0 pt-4" [ngClass]="{'max-w-60': !isScreenSmall}">
                    <div (click)="refreshMetrics()" class="cursor-pointer mt-0.5 h-12 w-12 rounded bg-primary-100 p-2">
                        <mat-icon class="ml-1 mt-1 icon-size-6 text-gray-700 dark:text-white"
                                  [svgIcon]="reportPeriod.id !== 5 ? 'mat_outline:refresh' : 'mat_outline:edit'">
                        </mat-icon>
                    </div>
                    <div class="pl-2">
                        <select matNativeControl class="w-full p-4 outline-1 rounded shadow"
                                [(ngModel)]="selectedReportPeriodId" (change)="applyReportPeriod()">
                            <option (click)="onReportPeriodClicked(reportPeriod)"
                                    *ngFor="let reportPeriod of reportPeriods"
                                    [value]="reportPeriod.id">{{ reportPeriod.period }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </div>

        <!-- The chart -->
        <div class="grid grid-cols-1 gap-8 w-full overflow-hidden bg-white shadow rounded-2xl">
            <div class="col-span-1 flex flex-col flex-auto bg-card shadow rounded-2xl">
                <div class="flex items-center justify-between mt-10 ml-10 mr-6 sm:mr-10">
                    <div class="flex flex-col">
                        <div class="mr-4 text-2xl md:text-3xl font-semibold tracking-tight leading-7">Return
                            Overview
                        </div>
                        <div class="flex flex-col">
                            <div class="font-medium text-secondary">{{ reportPeriod?.period }}</div>
                            <div *ngIf="startDate && endDate" class="flex flex-row items-center">
                                <div class="font-medium text-secondary">
                                    {{ startDate | date: 'shortDate' }}, {{ startDate | date: 'shortTime' }}
                                </div>
                                <div class="font-medium text-secondary px-2"> -</div>
                                <div class="font-medium text-secondary">
                                    {{ endDate | date: 'shortDate' }}, {{ endDate | date: 'shortTime' }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex flex-col flex-auto h-100 max-h-100">
                    <apx-chart class="flex-auto w-full h-100 max-h-100"
                        [chart]="chartOptions.chart"
                        [dataLabels]="chartOptions.dataLabels"
                        [fill]="chartOptions.fill"
                        [series]="chartOptions.series"
                        [stroke]="chartOptions.stroke"
                        [tooltip]="chartOptions.tooltip"
                        [xaxis]="chartOptions.xaxis"
                        [yaxis]="chartOptions.yaxis">
                    </apx-chart>
                </div>
            </div>
        </div>

        <!-- Summary Metric Cards -->
        <summary-metric-cards></summary-metric-cards>

        <!-- Location Breakdown -->
        <div class="flex flex-col w-full">
            <location-breakdown-table></location-breakdown-table>
        </div>
    </div>
} @else {
    <div class="flex flex-col flex-auto w-full h-screen bg-gray-50 justify-center items-center">
        <div class="w-full max-w-6xl flex flex-col items-center justify-center">
            <div class="container">
                <div class="loading-wave">
                    <div class="loading-bar"></div>
                    <div class="loading-bar"></div>
                    <div class="loading-bar"></div>
                    <div class="loading-bar"></div>
                </div>
            </div>
            <h1 class="mt-4 font-semibold text-2xl text-gray-700 text-center">Loading...</h1>
        </div>
    </div>
}
