import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AuthUtils} from 'app/core/auth/auth.utils';
import {UserService} from 'app/core/user/user.service';
import {catchError, finalize, Observable, of, switchMap, throwError} from 'rxjs';
import {Router} from "@angular/router";
import {environment} from "../../../environments/environment";
import {UserSession} from "./auth.types";

@Injectable({providedIn: 'root'})
export class AuthService {
    private _authenticated: boolean = false;

    constructor(private _httpClient: HttpClient,
                private _router: Router,
                private _userService: UserService) {
    }

    get authData(): UserSession {
        return JSON.parse(localStorage.getItem('emr-oversight-authData') || '{}');
    }

    set authData(token: UserSession) {
        localStorage.setItem('emr-oversight-authData', JSON.stringify(token));
    }


    // -----------------------------------------------------------------------------------------------------
    /** Sign in after getting a token */
    // -----------------------------------------------------------------------------------------------------
    signIn(credentials: { username: string; password: string }): Observable<any> {
        // Throw error, if the user is already logged in
        if (this._authenticated) {
            return throwError(() => new Error('User is already logged in.'));
        }

        // Prepare the headers
        const headers = new HttpHeaders({
            'X-Lt-Apikey': environment.oversight_api_key
        });

        // Make the HTTP POST request with headers
        return this._httpClient.post(`${environment.oversight_endpoint}/api/oversight/login`, credentials, {headers: headers}).pipe(
            switchMap((response: UserSession) => {

                // If the error code is not 0, throw the error
                if (response.ErrorCode !== 0) {
                    return throwError(() => new Error(response.ErrorDescription || 'An error occurred during sign in.'));
                }

                // Store the access token in the local storage
                this.authData = response;

                // Set the authenticated flag to true
                this._authenticated = true;

                // Store the user on the user service
                this._userService.user = {
                    email: AuthUtils.getUniqueNameFromToken(response?.AccessToken)
                };

                // Return a new observable with the response
                return of(response);
            }),
        );
    }

    // Add a flag to indicate if a token refresh operation is in progress
    private _isRefreshingToken = false;

    // -----------------------------------------------------------------------------------------------------
    /** Sign in using the access token */
    // -----------------------------------------------------------------------------------------------------
    refreshToken(): Observable<any> {
        if (this._isRefreshingToken) {
            return throwError(() => new Error('Token refresh in progress'));
        }

        this._isRefreshingToken = true;

        return this._httpClient.get(`${environment.oversight_endpoint}/api/oversight/refreshToken`, {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + this.authData.AccessToken,
                'X-Lt-Apikey': environment.oversight_api_key,
                'X-Lt-Art': environment.oversight_x_lt_art
            }),
        }).pipe(switchMap((response: UserSession) => {
                if (response && response['AccessToken']) {
                    this.authData = response;
                    this._authenticated = true;
                    this._userService.user = {
                        email: AuthUtils.getUniqueNameFromToken(response?.AccessToken)
                    };
                    return of(true);
                } else {
                    return throwError(() => new Error('Invalid refresh token response'));
                }
            }),
            catchError((error) => {
                this.signOut();
                return throwError(() => error);
            }),
            finalize(() => {
                this._isRefreshingToken = false;
            })
        );
    }

    // -----------------------------------------------------------------------------------------------------
    /** Sign out */
    // -----------------------------------------------------------------------------------------------------
    signOut(): Observable<any> {
        // Remove the access token from the local storage
        localStorage.removeItem('accessToken');
        localStorage.removeItem('emr-oversight-authData');
        localStorage.removeItem('emr-oversight-customer');

        // Set the authenticated flag to false
        this._authenticated = false;

        // Route to sign-out
        this._router.navigate(['/sign-in']).then();

        // Return the observable
        return of(true);
    }

    // -----------------------------------------------------------------------------------------------------
    /** Check the authentication status */
    // -----------------------------------------------------------------------------------------------------
    check(): Observable<boolean> {
        // Check the access token availability
        if (!this.authData.AccessToken) {
            return of(false);
        }

        this._userService.user = {
            email: AuthUtils.getUniqueNameFromToken(this.authData.AccessToken)
        };

        // If the access token exists but is expired, try to refresh it
        if (AuthUtils.isTokenExpired(this.authData.AccessToken)) {
            return this.refreshToken().pipe(
                switchMap(success => {
                    if (success) {
                        return of(true);
                    } else {
                        // If the token refresh fails, sign out
                        this.signOut();
                        return of(false);
                    }
                }),
                catchError(() => {
                    this.signOut();
                    return of(false);
                })
            );
        }

        // If the access token exists and it didn't expire, consider the user as authenticated
        this._authenticated = true;
        return of(true);
    }
}
