import {inject, Injectable} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {CustomDateRangeDialogComponent} from "./custom-date-range-dialog.component";
import {BehaviorSubject, Observable} from "rxjs";

@Injectable({providedIn: 'root'})
export class CustomDateRangeDialogService {
    private _resetForm: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private _matDialog: MatDialog = inject(MatDialog);

    get resetForm$(): Observable<boolean> {
        return this._resetForm.asObservable();
    }

    set resetForm(value: boolean) {
        this._resetForm.next(value);
    }

    open(): MatDialogRef<CustomDateRangeDialogComponent> {
        return this._matDialog.open(CustomDateRangeDialogComponent, {
            hasBackdrop: false,
            restoreFocus: false,
            disableClose: false,
            panelClass: 'custom-date-range-dialog-component-panel'
        });
    }

    isOpened(): boolean {
        return this._matDialog.openDialogs.length > 0;
    }
}
