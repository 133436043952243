import {NgClass, NgForOf, NgIf} from '@angular/common';
import {ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {FormBuilder, FormGroup, ReactiveFormsModule, UntypedFormGroup, Validators} from "@angular/forms";
import {MatInputModule} from "@angular/material/input";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {Subject, takeUntil} from "rxjs";
import {NgxMaterialTimepickerModule, NgxMaterialTimepickerTheme} from "ngx-material-timepicker";
import {CustomDateRangeDialogService} from "./custom-date-range-dialog.service";
import {DateTimeService} from "../../../../../core/services/date-time.service";
import {MatSelectModule} from "@angular/material/select";

export class ReportRequest {
    startDateTimeISO: string;
    endDateTimeISO: string;
}

@Component({
    selector: 'custom-date-range-dialog',
    templateUrl: './custom-date-range-dialog.component.html',
    styleUrls: ['./custom-date-range-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [NgIf, MatButtonModule, MatDialogModule, MatIconModule, NgClass, ReactiveFormsModule, NgForOf, MatInputModule, MatSlideToggleModule, MatDatepickerModule, NgxMaterialTimepickerModule, MatSelectModule],
})
export class CustomDateRangeDialogComponent implements OnInit, OnDestroy {
    public form: UntypedFormGroup;
    public startTime: string;
    public endTime: string;
    public primaryTheme: NgxMaterialTimepickerTheme = {
        container: {
            bodyBackgroundColor: '#f1f5f9',
            buttonColor: '#564de6'
        },
        dial: {
            dialBackgroundColor: '#f1f5f9',
            dialActiveColor: '#564de6',
            dialInactiveColor: '#564de6',
        },
        clockFace: {
            clockFaceBackgroundColor: '#FFFFFF',
            clockHandColor: '#564de6',
            clockFaceTimeInactiveColor: '#564de6',
            clockFaceInnerTimeInactiveColor: '#564de6',
            clockFaceTimeDisabledColor: '#FFFFFF',
            clockFaceTimeActiveColor: '#FFFFFF',
        }
    };
    public times: string[] = [];
    public isDialogHidden: boolean = false;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                private _customDateRangeDialogService: CustomDateRangeDialogService,
                private _changeDetectorRef: ChangeDetectorRef,
                private _dateTimeService: DateTimeService,
                private _formBuilder: FormBuilder,
                private _dialogRef: MatDialogRef<CustomDateRangeDialogComponent>) {
    }

    // -----------------------------------------------------------------------------------------------------
    /**  ngOnInit */
    // -----------------------------------------------------------------------------------------------------
    ngOnInit(): void {
        this.times = this._dateTimeService.buildTime(60);

        // listen for reset form event
        this._customDateRangeDialogService.resetForm$.pipe(takeUntil(this._unsubscribeAll)).subscribe((resetForm: boolean) => {
            if (resetForm && this.form) {
                this.form.enable();
                // set the form back to the default values
                const {startDay, endDay} = this._dateTimeService.getDateRange(7); // For last 7 days
                this.form.patchValue({
                    startDay,
                    startTime: '12:00 AM',
                    endDay,
                    endTime: '11:59 PM'
                });

                this._changeDetectorRef.detectChanges();
            }
        });

        // set form values
        const {startDay, endDay} = this._dateTimeService.getDateRange(7); // For last 7 days
        this.form = this._formBuilder.group({
            startDay: [startDay, Validators.required],
            startTime: ['12:00 AM', Validators.required],
            endDay: [endDay, Validators.required],
            endTime: ['11:59 PM', Validators.required]
        });
    }

    // -----------------------------------------------------------------------------------------------------
    /**  Unsubscribe from all subscriptions */
    // -----------------------------------------------------------------------------------------------------
    ngOnDestroy(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    /**  _generateForm */
    // -----------------------------------------------------------------------------------------------------
    private _generateForm(): FormGroup {
        const formControls = {};
        return this._formBuilder.group(formControls);
    }

    // -----------------------------------------------------------------------------------------------------
    /**  onConfirm */
    // -----------------------------------------------------------------------------------------------------
    onSubmit(): void {
        Object.keys(this.form.controls).forEach((key) => {
            this.form.controls[key].markAsTouched();
        });

        // Return if the form is invalid
        if (this.form.invalid) {
            return;
        }

        // Disable the form
        this.form.disable();

        // Convert start and end times to 24-hour format
        const startTime24 = this._dateTimeService.convertTime12to24(this.form.value.startTime);
        const endTime24 = this._dateTimeService.convertTime12to24(this.form.value.endTime);

        // Combine date and time for start and end, then convert to ISO 8601 string format
        const startDateTimeISO = new Date(`${this.getDates(this.form.value.startDay)}T${startTime24}`).toISOString();
        const endDateTimeISO = new Date(`${this.getDates(this.form.value.endDay)}T${endTime24}`).toISOString();

        // close the dialog and pass the form's value
        this._dialogRef.close({startDateTimeISO, endDateTimeISO});
    }

    // -----------------------------------------------------------------------------------------------------
    /**  onCancel */
    // -----------------------------------------------------------------------------------------------------
    onCancel(): void {
        this._dialogRef.close();
    }

    // -----------------------------------------------------------------------------------------------------
    /** On date change */
    // -----------------------------------------------------------------------------------------------------
    getDates(event: any) {
        const date = new Date(event?.ts ?? event);
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        return `${year}-${month}-${day}`;
    }
}
