/* eslint-disable */
import {DateTime} from 'luxon';

/* Get the current instant */
const now = DateTime.now();

export const recyclingData = {
    recycling_breakdown: {
        customer_name: 'Chick-Fil-A',
        total: 349,
        recycled: 304,
        on_hand: 45,
        recycle_rate: 84.68,
        details: [
            {
                type: 'distribution_center',
                code: 'Distribution Center',
                name: 'RiverEdge Supply Center',
                recycled: 229,
                on_hand: 23,
                recycle_rate: 90.87,
                suppliers: [
                    {
                        type: 'supplier',
                        code: 'supplier',
                        name: 'Blue Spring Farms',
                        recycled: 107,
                        on_hand: 10,
                        recycle_rate: 91.45
                    },
                    {
                        type: 'supplier',
                        code: 'supplier',
                        name: 'Lucky Day Produce',
                        recycled: 122,
                        on_hand: 13,
                        recycle_rate: 90.37
                    }
                ]
            },
            {
                type: 'distribution_center',
                code: 'Distribution Center',
                name: 'MapleHub Center',
                recycled: 120,
                on_hand: 22,
                recycle_rate: 84.51,
                suppliers: [
                    {
                        type: 'supplier',
                        code: 'supplier',
                        name: 'Hillside Dairy Farms',
                        recycled: 41,
                        on_hand: 2,
                        recycle_rate: 95.35
                    },
                    {
                        type: 'supplier',
                        code: 'supplier',
                        name: 'GoldenGrain Mills',
                        recycled: 24,
                        on_hand: 8,
                        recycle_rate: 75.00
                    },
                    {
                        type: 'supplier',
                        code: 'supplier',
                        name: 'RedLeaf Fresh Produce',
                        recycled: 55,
                        on_hand: 12,
                        recycle_rate: 82.09
                    },
                ]
            },
        ]
    }
};
