<!-- Disclaimer -->
<div class="flex flex-col w-full gap-y-4" *ngIf="dashboardStatCards.length > 0">
    <!-- Stats on Routes -->
    <div class="grid gap-4" [ngClass]="{'grid-cols-2': isScreenSmall, 'grid-cols-3': !isScreenSmall && isFoodServiceModel, 'grid-cols-5': !isScreenSmall && !isFoodServiceModel}">
        @for (dashboardStatCard of dashboardStatCards; track dashboardStatCard) {
            <!-- Stat -->
            <div class="min-w-0 rounded-lg shadow bg-white dark:bg-gray-800">
                <div class="p-4 flex justify-center items-center w-full h-full">
                    <!-- Stat Icon & Content Container -->
                    <div class="flex justify-center items-center w-full">
                        <!-- Stat Icon -->
                        <div
                            class="p-3 rounded-full text-{{dashboardStatCard.iconColor}}-500 dark:text-red-100 bg-{{dashboardStatCard.iconColor}}-100 dark:bg-{{dashboardStatCard.iconColor}}-500 mr-4">
                            <mat-icon class="text-current icon-size-5" [svgIcon]="dashboardStatCard.icon"></mat-icon>
                        </div>
                        <!-- Stat Content -->
                        <div class="flex flex-col w-full">
                            <!-- Stat Label -->
                            <div class="text font-semibold text-gray-600 dark:text-gray-400">{{ dashboardStatCard.title }}</div>
                            <div class="text-sm text-secondary dark:text-gray-400">{{ dashboardStatCard.description }}</div>
                            <!-- Stat Values -->
                            <div class="flex items-center gap-2">
                                <div class="text-lg font-semibold text-gray-700 dark:text-gray-200" [innerHtml]="dashboardStatCard.value"></div>

                          </div>
                        </div>
                    </div>
                </div>
            </div>
        }
    </div>
</div>
