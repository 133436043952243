/* eslint-disable */
import {PlatformNavigationItem} from '@platform/components/navigation';

const nav: PlatformNavigationItem[] = [
    {
        id: 'customer-dashboard',
        title: 'Dashboard',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/customer/recycling/dashboard'
    }
];

export const defaultNavigation = nav;
export const compactNavigation = nav;
export const futuristicNavigation = nav;
export const horizontalNavigation = nav;
