import { inject, Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from "../../../environments/environment";

@Injectable({ providedIn: 'root' })
export class IconsService {
    private iconSets = [
        { namespace: 'custom', url: 'custom.svg' },
        { namespace: '', url: 'material-twotone.svg' },  // Default namespace
        { namespace: 'mat_outline', url: 'material-outline.svg' },
        { namespace: 'mat_solid', url: 'material-solid.svg' },
        { namespace: 'feather', url: 'feather.svg' },
        { namespace: 'heroicons_outline', url: 'heroicons-outline.svg' },
        { namespace: 'heroicons_solid', url: 'heroicons-solid.svg' },
        { namespace: 'heroicons_mini', url: 'heroicons-mini.svg' }
    ];

    constructor(private matIconRegistry: MatIconRegistry,
                private domSanitizer: DomSanitizer) {
        const baseUrl = environment.dashboard_app_url.replace(/\/+$/, ""); // Remove trailing slashes if any
        this.registerIconSets(baseUrl);
    }

    private registerIconSets(baseUrl: string): void {
        this.iconSets.forEach(icon => {
            const iconUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(`${baseUrl}/assets/icons/${icon.url}`);
            if (icon.namespace) {
                this.matIconRegistry.addSvgIconSetInNamespace(icon.namespace, iconUrl);
            } else {
                this.matIconRegistry.addSvgIconSet(iconUrl);
            }
        });
    }
}
