import {HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest} from '@angular/common/http';
import {inject} from '@angular/core';
import {AuthService} from 'app/core/auth/auth.service';
import {AuthUtils} from 'app/core/auth/auth.utils';
import {BehaviorSubject, catchError, filter, Observable, switchMap, take, throwError} from 'rxjs';
import {ToastrService} from "ngx-toastr";


export const authInterceptor = (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
    const _authService = inject(AuthService);
    const _toastService = inject(ToastrService);

    // Add a BehaviorSubject to handle token refresh
    const isRefreshing = new BehaviorSubject<boolean>(false);

    // Check for the custom header
    const useCapitalBearer = req.headers.get('Use-Capital-Bearer') === 'true';
    req = req.clone({ headers: req.headers.delete('Use-Capital-Bearer') });  // Remove the custom header
    let authHeader = useCapitalBearer ? 'Bearer ' : 'bearer ';  // Adjust based on the custom header
    let newReq = req.clone();
    if (_authService.authData.AccessToken && !AuthUtils.isTokenExpired(_authService.authData.AccessToken)) {
        newReq = req.clone({
            headers: req.headers.set('Authorization', authHeader + _authService.authData.AccessToken),
        });
    }

    // Response
    return next(newReq).pipe(
        catchError((error) => {

            // If the error contains 'api.net/recycle/v1/returner/' just ignore it
            if (error.url && error.url.includes('api.net/recycle/v1/returner/')) {
                return throwError(() => error);
            }

            if (error instanceof HttpErrorResponse && error.status === 401) {
                // Check if we are already refreshing the token
                if (!isRefreshing.value) {
                    isRefreshing.next(true);
                    return _authService.refreshToken().pipe(
                        switchMap(() => {
                            isRefreshing.next(false);
                            // Use the new access token for the retry request
                            const retryReq = req.clone({
                                headers: req.headers.set('Authorization', 'Bearer ' + _authService.authData.AccessToken)
                            });
                            return next(retryReq);
                        }),
                        catchError((refreshError) => {

                            let message = 'Session expired. Please log in again.';
                            _toastService.error(message);
                            return throwError(() => refreshError);
                        })
                    );
                } else {
                    // Wait for the token refresh to complete, then retry the request
                    return isRefreshing.pipe(filter(refreshing => !refreshing), take(1), switchMap(() => {
                            // Use the new access token for the retry request
                            const retryReq = req.clone({
                                headers: req.headers.set('Authorization', 'Bearer ' + _authService.authData.AccessToken)
                            });
                            return next(retryReq);
                        })
                    );
                }
            } else {
                let message = extractErrorMessage(error) ?? 'An unknown error occurred';
                if (message !== undefined && error.status !== 401) {
                    _toastService.error(message);
                }
                return throwError(() => error);
            }
        }),
    );


    function extractErrorMessage(error: any): string {
        // First, check if the 'error' field exists and contains any useful message
        if (error?.error && (typeof error?.error === 'string' || error?.error?.detail || error?.error?.ErrorMessage)) {
            return typeof error?.error === 'string' ? error?.error : (error?.error?.detail || error?.error?.ErrorMessage);
        }
        // If not, check the 'message' field
        if (error?.message && typeof error?.message === 'string') {
            return error?.message;
        }
        // Fallback to a generic message if none of the above is available
        return 'An unknown error occurred';
    }
};
