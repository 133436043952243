import {Metrics} from "./metrics.model";

export class RecyclerMetrics {
    customerId: number;
    name: string;
    isRecycleEnabled: boolean;
    metrics: Metrics;

    constructor(customerId: number, name: string, isRecycleEnabled: boolean, metrics: Metrics) {
        this.customerId = customerId;
        this.name = name;
        this.isRecycleEnabled = isRecycleEnabled;
        this.metrics = metrics;
    }
}
