export const environment = {
    appVersion: require('../../package.json').version,
    dashboard_app_url: 'https://recycle-customer-dashboard.dctcargo.com/',
    includeRoutes: true,
    oversight_api_key: '3A20A540-A777-4C6A-9311-C4AEA0922A3C',
    oversight_x_lt_art: 'f523615a-7666-42af-ad3d-aa326eec6532',
    oversight_endpoint: 'https://oversightapi.locustraxx.com',
    production: true,
    recycling_api_endpoint: 'https://api.dctcargo.com'
};
