import {Injectable} from "@angular/core";
import {Observable, ReplaySubject, tap, catchError, of, Subject, takeUntil} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {environment} from "environments/environment";
import {RecyclerLocation} from "../models/recycler-location.model";
import { MetricRequestDefaults } from "../models/metric-request.model";
import { OversightService } from "./oversight.service";

@Injectable({providedIn: 'root'})
export class RecyclerLocationService {
    private metricRequestDefaults: MetricRequestDefaults;

    constructor(private _httpClient: HttpClient) {
    }

    // -----------------------------------------------------------------------------------------------------
    //* Gets and sets the recycling locations
    // -----------------------------------------------------------------------------------------------------
    private _recycleLocations: ReplaySubject<RecyclerLocation[]> = new ReplaySubject<RecyclerLocation[]>(1);
    get recyclerLocations$(): Observable<RecyclerLocation[]> {
        return this._recycleLocations.asObservable();
    }

    set recyclerLocations(value: RecyclerLocation[]) {
        this._recycleLocations.next(value);
    }

    getRecyclerLocations(recyclerId: number): Observable<RecyclerLocation[]> {
        return this._httpClient.get<RecyclerLocation[]>(`${environment.recycling_api_endpoint}/recycle/v1/returner/${recyclerId}/location`).pipe(
            tap((response: any) => {
                this._recycleLocations.next(response);
            }),
            catchError(() => {
                this._recycleLocations.next([]);
                return of([]);
            })
        );
    }

    // -----------------------------------------------------------------------------------------------------
    //* Gets the recycling locations metrics
    // -----------------------------------------------------------------------------------------------------
    private _recyclerLocationMetrics: ReplaySubject<RecyclerLocation[]> = new ReplaySubject<RecyclerLocation[]>(1);
    get recyclerLocationMetrics$(): Observable<RecyclerLocation[]> {
        return this._recyclerLocationMetrics.asObservable();
    }

    set recyclerLocationMetrics(value: RecyclerLocation[]) {
        this._recyclerLocationMetrics.next(value);
    }

    getRecyclerLocationMetrics(isFoodServiceModel: boolean, recyclerId: number, startDate?: string, endDate?: string): Observable<RecyclerLocation[]> {
        const defaults = this.handleDefaults(recyclerId, startDate, endDate);
        const url = `${environment.recycling_api_endpoint}/recycle/v1/returner/${defaults.oversightCustomerId}/location/metrics?startDate=${defaults.startDate}&endDate=${defaults.endDate}&isFoodServiceModel=${isFoodServiceModel}`;
        return this._httpClient.get<RecyclerLocation[]>(url).pipe(
            tap((response: any) => {
                this._recycleLocations.next(response['locations']);
            }),
            catchError(() => {
                this._recyclerLocationMetrics.next([]);
                return of([]);
            })
        );
    }

    // Private method to handle the default values
    private handleDefaults(oversightCustomerId: number, startDate?: string, endDate?: string): MetricRequestDefaults {
        const today = new Date();
        return {
            oversightCustomerId: oversightCustomerId,
            startDate: startDate ?? new Date(today.getFullYear(), 0, 1).toISOString(),
            endDate: endDate ?? new Date(today.getFullYear(), today.getMonth(), today.getDate() + 2).toISOString(),
            isFoodServiceModel: false
        };
    }
}
