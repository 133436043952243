import {Injectable} from "@angular/core";
import {Observable, ReplaySubject, tap, catchError, of} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {environment} from "environments/environment";
import {RecyclerLocation} from "../models/recycler-location.model";

@Injectable({providedIn: 'root'})
export class OversightService {

    constructor(private _httpClient: HttpClient) {
    }

    // -----------------------------------------------------------------------------------------------------
    //* Gets and sets the recycling location's food service model flag
    // -----------------------------------------------------------------------------------------------------
    private _isFoodServiceModel: ReplaySubject<any> = new ReplaySubject<any>(1);
    get isFoodServiceModel$(): Observable<any> {
        return this._isFoodServiceModel.asObservable();
    }

    set isFoodServiceModel(value:any) {
        this._isFoodServiceModel.next(value);
    }

    getFoodServiceModelFlag(oversightCustomerId: number): Observable<any> {
        return this._httpClient.get<any>(`${environment.recycling_api_endpoint}/recycle/v1/customer/${oversightCustomerId}/food-service-model`).pipe(
            tap((isFoodServiceModel: any) => {
                this._isFoodServiceModel.next(isFoodServiceModel);
            }),
            catchError(() => {
                this._isFoodServiceModel.next(false);
                return of(false);
            })
        );
    }
}