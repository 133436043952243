import { Injectable } from '@angular/core';
import { PlatformMockApiService } from '@platform/lib/mock-api';
import { recyclingData } from 'app/mock-api/dashboards/recycling/data';
import { cloneDeep } from 'lodash-es';

@Injectable({providedIn: 'root'})
export class RecyclingMockApi
{
    private _recyclingData: any = recyclingData;

    constructor(private _platformMockApiService: PlatformMockApiService)
    {
        // Register Mock API handlers
        this.registerHandlers();
    }

    // -----------------------------------------------------------------------------------------------------
    // Register Mock API handlers
    // -----------------------------------------------------------------------------------------------------
    registerHandlers(): void
    {
        this._platformMockApiService.onGet('api/dashboards/recycling').reply(() => [200, cloneDeep(this._recyclingData)]);
    }
}
