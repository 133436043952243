import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class DateTimeService {
    /**
     * Gets a date range relative to today.
     * @param daysBeforeStart Number of days before today to set as the start date.
     * @param daysAfterEnd Optional. Number of days from today to set as the end date. Defaults to 0 (today).
     * @returns An object containing the start and end dates as strings in 'YYYY-MM-DD' format.
     */
    public getDateRange(daysBeforeStart: number, daysAfterEnd: number = 0): { startDay: string; endDay: string } {
        const today = new Date();
        const startDate = new Date(today);
        startDate.setDate(today.getDate() - daysBeforeStart);

        const endDate = new Date(today);
        endDate.setDate(today.getDate() + daysAfterEnd);

        return {
            startDay: this.formatDate(startDate),
            endDay: this.formatDate(endDate)
        };
    }

    // ------------------------------------------------------------------------------------------
    /** Converts a 12-hour time to 24-hour time
     * @param time12h The 12-hour time to convert.
     * @returns The 24-hour time.
     * */
        // ------------------------------------------------------------------------------------------
    public convertTime12to24 = (time12h: string) => {
        const [time, modifier] = time12h.split(' ');
        let [hours, minutes] = time.split(':');
        if (hours === '12') {
            hours = '00';
        }
        if (modifier === 'PM') {
            hours = String(parseInt(hours, 10) + 12);
        }
        return `${hours}:${minutes}`;
    };

    // ------------------------------------------------------------------------------------------
    /** Formats a date to 'YYYY-MM-DD' for compatibility with input[type=date]
     * @param date
     * @private
     */
    // ------------------------------------------------------------------------------------------
    private formatDate(date: Date): string {
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2); // JS months are 0-based
        const day = ('0' + date.getDate()).slice(-2);
        return `${year}-${month}-${day}`;
    }

    //-------------------------------------------------
    /** Builds the time array for the time picker */
    //-------------------------------------------------
    public buildTime(increments: number = 5): string[] {
        const start = 0; // Start time in minutes from midnight
        const end = 24 * 60; // End time in minutes from midnight
        let times = []; // Array to store time values
        for (let i = start; i < end; i += increments) {
            const hh = Math.floor(i / 60);
            const mm = i % 60;
            const hour = hh === 0 || hh === 12 ? 12 : hh % 12; // We handle the case for 12 AM and PM
            const period = hh < 12 ? 'AM' : 'PM';
            times.push(`${hour}:${mm < 10 ? '0' : ''}${mm} ${period}`);
        }
        return times;
    }

    // -----------------------------------------------------------------------------------------------------
    /** Converts a 24 hour time string to 12 hour format */
    // -----------------------------------------------------------------------------------------------------
    public convertTo12HourFormat(time: string): string {
        let [hours, minutes] = time.split(':').map(val => parseInt(val, 10));
        const AMorPM = hours < 12 ? 'AM' : 'PM';
        const hours12 = hours === 0 ? 12 : (hours > 12 ? hours - 12 : hours);
        return `${hours12}:${minutes.toString().padStart(2, '0')} ${AMorPM}`;
    }

    // -----------------------------------------------------------------------------------------------------
    /** Converts a 12 hour time string to 24 hour format */
    // -----------------------------------------------------------------------------------------------------
    public convertTo24HourFormat(time: string): string {
        let [hours, minutes] = time.split(/[:\s]/).map(val => parseInt(val, 10));
        const period = time.match(/AM|PM/i)[0];
        if (period.toUpperCase() === "PM" && hours !== 12) {
            hours += 12;
        } else if (period.toUpperCase() === "AM" && hours === 12) {
            hours = 0;
        }
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    }
}
