import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {CurrencyPipe, NgClass, NgIf} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {DashboardStatCard} from "../../interfaces/dashboard-stat-card.interface";
import {DashboardService} from "../../../dashboard.service";
import {Subject, takeUntil} from "rxjs";
import {PlatformMediaWatcherService} from "../../../../../../@platform/services/media-watcher";
import {RecyclerMetrics} from "../../../../../core/models/recycler-metrics.model";
import { OversightService } from 'app/core/services/oversight.service';

@Component({
    selector: 'summary-metric-cards',
    templateUrl: './summary-metric-cards.component.html',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [CurrencyPipe, MatIconModule, NgClass, NgIf],
})
export class SummaryMetricCards implements OnInit {
    public isScreenSmall: boolean = false;
    public recyclerMetrics: RecyclerMetrics
    public dashboardStatCards: DashboardStatCard[] = [];
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    public isFoodServiceModel: boolean = false;

    constructor(private _changeDetectorRef: ChangeDetectorRef,
                private _dashboardService: DashboardService,
                private _platformMediaWatcherService: PlatformMediaWatcherService,
                private _oversightService: OversightService) {

    }

    ngOnInit() {
        this._platformMediaWatcherService.onMediaChange$.pipe(takeUntil(this._unsubscribeAll)).subscribe(({matchingAliases}) => {
            this.isScreenSmall = !matchingAliases.includes('md');
            this._changeDetectorRef.markForCheck();
        });
        this._oversightService.isFoodServiceModel$.pipe(takeUntil(this._unsubscribeAll)).subscribe((isFoodServiceModel: boolean) => {
            this.isFoodServiceModel = isFoodServiceModel;
            this._changeDetectorRef.markForCheck();
        });
        this._dashboardService.recyclerMetrics$.pipe(takeUntil(this._unsubscribeAll)).subscribe((recyclerMetrics: RecyclerMetrics) => {
            this.recyclerMetrics = recyclerMetrics;
            if(this.isFoodServiceModel){
                this.buildMetricCardsForFoodServiceModel();
            }else{
                this.buildMetricCardsForNonFoodServiceModel();
            }
            this._changeDetectorRef.markForCheck();
        });
    }

    // -----------------------------------------------------------------------------------------------------
    /** build metric cards for non FoodService Model*/
    // -----------------------------------------------------------------------------------------------------
    buildMetricCardsForNonFoodServiceModel(): void {
        this.dashboardStatCards = [
            {
                icon: 'mat_solid:recycling', // best fit for returned/recycling
                iconColor: 'primary',
                title: `Returned`,
                description: `The total number of items returned for recycling across all distribution centers.`,
                value: `${(this.recyclerMetrics.metrics.recycledCount).toLocaleString('en-US')}`,
            },
            {
                icon: 'mat_solid:report_problem', // indicating issues or damage
                iconColor: 'primary',
                title: `Damaged`,
                description: `The total number of devices that arrived damaged prior to recycling.`,
                value: `${this.recyclerMetrics.metrics.damagedCount.toLocaleString('en-US')}`,
            },
            {
                icon: 'mat_solid:block', // indicates something is restricted or not allowed
                iconColor: 'primary',
                title: `Ineligible`,
                description: `The total number of devices that are not eligible for recycling.`,
                value: `${this.recyclerMetrics.metrics.ineligibleCount.toLocaleString('en-US')}`,

            },
            {
                icon: 'mat_solid:check_circle', // shows something is available or ready
                iconColor: 'primary',
                title: `Available`,
                description: `The total number of devices available for recycling.`,
                value: `${this.recyclerMetrics.metrics.availableCount.toLocaleString('en-US')}`,
            },
            {
                icon: 'mat_solid:autorenew', // recycling or refresh symbol
                iconColor: 'primary',
                title: `Return Rate`,
                description: `The percentage of items returned for recycling across all distribution centers.`,
                value: `${Math.ceil(this.recyclerMetrics.metrics.recycleRate)}%`,
            }
        ]
    }

    // -----------------------------------------------------------------------------------------------------
    /** build metric cards for FoodService Model*/
    // -----------------------------------------------------------------------------------------------------
    buildMetricCardsForFoodServiceModel(): void {
        this.dashboardStatCards = [
            {
                icon: 'mat_solid:recycling', // best fit for returned/recycling
                iconColor: 'primary',
                title: `Returned`,
                description: `The total number of items returned for recycling across all distribution centers.`,
                value: `${(this.recyclerMetrics.metrics.recycledCount).toLocaleString('en-US')}`,
            },
            {
                icon: 'mat_solid:report_problem', // indicating issues or damage
                iconColor: 'primary',
                title: `Damaged`,
                description: `The total number of devices that arrived damaged prior to recycling.`,
                value: `${this.recyclerMetrics.metrics.damagedCount.toLocaleString('en-US')}`,
            },
            {
                icon: 'mat_solid:block', // indicates something is restricted or not allowed
                iconColor: 'primary',
                title: `Ineligible`,
                description: `The total number of devices that are not eligible for recycling.`,
                value: `${this.recyclerMetrics.metrics.ineligibleCount.toLocaleString('en-US')}`,
            },
        ];
    }

    // -----------------------------------------------------------------------------------------------------
    /** On destroy */
    // -----------------------------------------------------------------------------------------------------
    ngOnDestroy(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
        this.dashboardStatCards = [];
    }

}
