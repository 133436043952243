import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {catchError, Observable, of, ReplaySubject, Subject, takeUntil, tap} from 'rxjs';
import {MetricsHistoryContainer} from "../../core/models/metrics-history.model";
import {RecyclerMetrics} from "../../core/models/recycler-metrics.model";
import {
    RecyclerLocationAndSupplierMetrics
} from "../../core/view-models/recycler-location-and-supplier-metrics-view.model";
import {environment} from "../../../environments/environment";
import { MetricRequestDefaults } from 'app/core/models/metric-request.model';

@Injectable({providedIn: 'root'})
export class DashboardService {
    private metricRequestDefaults: MetricRequestDefaults;

    constructor(private _httpClient: HttpClient) {
    }

    // Private method to handle the default values
    private handleDefaults(oversightCustomerId: number, startDate?: string, endDate?: string): MetricRequestDefaults {
        const today = new Date();
        return {
            oversightCustomerId: oversightCustomerId,
            startDate: startDate ?? new Date(today.getFullYear(), 0, 1).toISOString(),
            endDate: endDate ?? new Date(today.getFullYear(), today.getMonth(), today.getDate() + 2).toISOString(),
            isFoodServiceModel: false
        };
    }

    // Default values for error handling
    private defaultMetricsHistoryContainer: MetricsHistoryContainer = new MetricsHistoryContainer([]);
    private defaultRecyclerMetrics: RecyclerMetrics = new RecyclerMetrics(0, '', false, {availableCount: 0, recycledCount: 0, damagedCount:0, ineligibleCount:0, recycleRate: 0}); // Adjust to your Metrics structure
    private defaultRecyclerLocationAndSupplierMetrics: RecyclerLocationAndSupplierMetrics[] = [];

    // -----------------------------------------------------------------------------------------------------
    //* Gets the recycling metrics history for the current user.
    // -----------------------------------------------------------------------------------------------------
    private _metricsHistory: ReplaySubject<MetricsHistoryContainer> = new ReplaySubject<MetricsHistoryContainer>(1);

    get metricsHistory$(): Observable<MetricsHistoryContainer> {
        return this._metricsHistory.asObservable();
    }
    set metricsHistory(value: MetricsHistoryContainer) {
        this._metricsHistory.next(value);
    }

    getMetricsHistory(isFoodServiceModel: boolean, oversightCustomerId?: number, startDate?: string, endDate?: string): Observable<MetricsHistoryContainer> {
        const defaults = this.handleDefaults(oversightCustomerId, startDate, endDate);
        const url = `${environment.recycling_api_endpoint}/recycle/v1/returner/${defaults.oversightCustomerId}/metrics/history?startDate=${defaults.startDate}&endDate=${defaults.endDate}&isFoodServiceModel=${isFoodServiceModel}`;
        return this._httpClient.get<MetricsHistoryContainer>(url).pipe(
            tap((response) => {
                this._metricsHistory.next(response);
            }),
            catchError(() => {
                this._metricsHistory.next(this.defaultMetricsHistoryContainer);
                return of(this.defaultMetricsHistoryContainer);
            })
        );
    }

    // -----------------------------------------------------------------------------------------------------
    //* Gets the recycling metrics for the current user.
    // -----------------------------------------------------------------------------------------------------
    private _recyclerMetrics: ReplaySubject<RecyclerMetrics> = new ReplaySubject<RecyclerMetrics>(1);

    get recyclerMetrics$(): Observable<RecyclerMetrics> {
        return this._recyclerMetrics.asObservable();
    }

    getRecyclerMetrics(isFoodServiceModel: boolean, oversightCustomerId?: number, startDate?: string, endDate?: string): Observable<RecyclerMetrics> {
        const defaults = this.handleDefaults(oversightCustomerId, startDate, endDate);
        const url = `${environment.recycling_api_endpoint}/recycle/v1/returner/${defaults.oversightCustomerId}/metrics?startDate=${defaults.startDate}&endDate=${defaults.endDate}&isFoodServiceModel=${isFoodServiceModel}`;
        return this._httpClient.get<RecyclerMetrics>(url).pipe(
            tap((response) => {
                this._recyclerMetrics.next(response);
            }),
            catchError(() => {
                this._recyclerMetrics.next(this.defaultRecyclerMetrics);
                return of(this.defaultRecyclerMetrics);
            })
        );
    }

    // -----------------------------------------------------------------------------------------------------
    //* Gets the recycling metrics for the current user.
    // -----------------------------------------------------------------------------------------------------
    private _recyclerLocationAndSupplierMetrics: ReplaySubject<RecyclerLocationAndSupplierMetrics[]> = new ReplaySubject<RecyclerLocationAndSupplierMetrics[]>(1);

    get recyclerLocationAndSupplierMetrics$(): Observable<RecyclerLocationAndSupplierMetrics[]> {
        return this._recyclerLocationAndSupplierMetrics.asObservable();
    }
}
