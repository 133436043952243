import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'recycleRate',
    standalone: true,
})
export class RecycleRatePipe implements PipeTransform {
    transform(recycleRate: number | null | undefined): string {
        if (recycleRate === null || recycleRate === undefined) return `<span>---</span>`;

        if (recycleRate < 30) {
            return `<span class="flex items-center">
                       <span class="flex items-end w-1 h-4 bg-red-200 rounded overflow-hidden">
                            <span class="flex w-full h-1/3 bg-red-600"></span>

                       </span>
                    </span>`;
        } else if (recycleRate >= 30 && recycleRate < 80) {
            return `<span class="flex items-end w-1 h-4 bg-orange-200 rounded overflow-hidden">
                <span class="flex w-full h-2/4 bg-orange-400"></span>
              </span>`;
        } else {
            return `<span class="flex items-end w-1 h-4 bg-green-100 rounded overflow-hidden">
                <span class="flex w-full h-full bg-green-400"></span>
              </span>`;
        }
    }
}
